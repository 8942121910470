<template>
    <div class="account-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="row mb-4">
                <div class="col-md-6 col-lg-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search prospects"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <div class="col-auto ms-auto">
                    <ui-button variant="primary" to="/prospects/register">
                        Add prospect
                    </ui-button>
                </div>
            </div>
            <ui-table class="mb-4" :items="accounts" :fields="fields" :busy="busy" v-model:sort-by="filters.sort_by" v-model:sort-type="filters.sort_type">
                <template #cell(name)="data">
                    <ui-link
                        v-if="data.item.first_name"
                        :to="{ name: 'accounts-info', query: { id: data.item.id } }"
                    >
                        {{ data.item.first_name }} {{ data.item.last_name || '' }}
                    </ui-link>
                    <span v-else class="text-muted fw-bold fst-italic"> - </span>
                    <p class="mb-0">
                        <strong class="account-list__strong">ID:</strong> S023451246
                    </p>
                </template>
                <template #cell(created_by)>
                    Samuel Galeana
                </template>
                <template #cell(language)>
                    (es-MX) Spanish - México
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('L') }}
                    <br />
                    {{ $dayjs(data.item.created_at).format('LT') }}
                </template>
                <template #cell(contact)="data">
                    <div class="account-list__contact-buttons">
                        <contact-links
                            :phone="data.item.phone || null"
                            :email="data.item.email || null"
                        />
                    </div>
                </template>
                <template #cell(tracking)>
                    <ui-stage-status
                        class="account-list__stage-status"
                        :stage="['CO', 'CN', 'PR', 'NR', 'CW', 'CL'][Math.floor(Math.random() * 6)]"
                    />
                </template>
                <template #cell(status)>
                    <ui-status class="fs-13px" status="in process" />
                </template>
                <template #cell(actions)>
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <span class="fas fa-ellipsis-v"></span>
                        </template>
                        <b-dropdown-item>
                            Update advisor
                        </b-dropdown-item>
                        <b-dropdown-item>
                            Edit
                        </b-dropdown-item>
                        <b-dropdown-item>
                            Delete
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import ContactLinks from '@/components/ui/ContactLinks';
import UiButton from '@/components/ui/buttons/Button';
import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link';
import UiPagination from '@/components/ui/Pagination';
import UiStageStatus from '@/components/ui/StageStatus';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton';

import table_mixin from '@/mixins/tables.mixin';

export default {
    components: {
        ContactLinks,
        UiButton,
        UiInput,
        UiLink,
        UiPagination,
        UiStageStatus,
        UiStatus,
        UiTable,
        UiTableSkeleton,
    },
    mixins: [table_mixin],
    data() {
        return {
            accounts: null,
            busy: false,
            fields: [
                {
                    key: 'name',
                    label: 'Name'
                },
                {
                    key: 'created_by',
                    label: 'Created by',
                },
                {
                    key: 'language',
                    label: 'Language',
                },
                {
                    key: 'created_at',
                    label: 'Registration',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'contact',
                    label: 'Contact',
                },
                {
                    key: 'tracking',
                    label: 'Follow up',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'actions',
                    label: 'Actions',
                },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                sort_by: null,
                sort_type: null,
            },
            inititialLoading: true,
            loading: false,
            total_pages: null,
        };
    },
    watch: {
        filters: {
            immediate: false,
            handler() {
                this.getProspects();
            },
            deep: true,
        },
    },
    methods: {
        async getProspects() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;
                const params = {
                    page: this.filters.page,
                    limit: this.filters.limit,
                    sort_by: this.filters.sort_by,
                    sort_type: this.filters.sort_type,
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/accounts', { params });
                this.total_pages = data.pages;
                this.accounts = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
    },
};
</script>

<style lang="scss">
.account-list {
    &__flag {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(#000000, 0.15);
        height: 24px;
        margin-right: 8px;
        width: 24px;
    }

    &__strong {
        font-weight: 600;
        color: $general-black;
    }

    &__contact-buttons {
        display: flex;
        gap: 5px;
    }

    &__contact-button {
        align-items: center;
        background-color: #eeeeee;
        border-radius: 4px;
        color: $white !important;
        display: flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        line-height: 1;
        padding: 8px;
        text-decoration: none;
        transition: 300ms all;
        width: 32px;

        &--whatsapp {
            background-color: #2ecc71;
            font-size: 18px;

            &:hover {
                background-color: rgba(#2ecc71, 0.8);
            }
        }

        &--phone {
            background-color: #0283cc;

            &:hover {
                background-color: rgba(#0283cc, 0.8);
            }
        }

        &--email {
            background-color: #ffb136;

            &:hover {
                background-color: rgba(#ffb136, 0.8);
            }
        }
    }

    &__stage-status {
        cursor: pointer;
    }
}
</style>
